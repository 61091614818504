import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import {MaterialUIFormSubmit} from './../Submit/MaterialUIFormSubmit';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [
		{id:1,title:'Base ML Model',desc:"Base models for NLP, Vision, Speech related tasks.",img:"/resnet.png"}, 
		{id:2,title:'Model Finetune',desc:"Finetune base model to fit your application.",img:"/finetune.png"}, 
		{id:3,title:'Prototyping',desc:"Prototype an application to see how it works with early users.",img:"/prototyping.png"},
		{id:4,title:'Project Dev',desc:"End to end project development for enterprise customers. Data analysis, dashboard, ROI, market forecasting, process automation, fault detection etc.",img:"/project_management.png"}, 
		{id:5,title:'Remote Developer',desc:"Skilled, affordable, flexible  developers.",img:"/remote_developer.jpeg"}, 
		{id:6,title:'Project Consultancy',desc:"Ideation, resource allocation, development, deployment- get consultancy at every stage of your project.",img:"/consultancy.png"},
/*		{id:7,title:'Vision'}, {id:8,title:'NLP'}, {id:9,title:'Voice'},*/
		{id:10,title:'Augmented Reality',desc:"AR for automotive, construction, heavy machinery industries.",img:"/ar_street.jpeg"}, 
		{id:11,title:'Metaverse',desc:"Develop VR applications for the metaverse.",img:"/vr.jpeg"}, 
		{id:12,title:'Chatbot/LLM',desc:"Prompt engineering, copilot, assistant.",img:"/llm.jpeg"},
		{id:13,title:'Web',desc:"Web design, content, hosting, maintenance.",img:"/web_development.jpeg"}, 
		{id:14,title:'Mobile',desc:"App design, content, maintenance",img:"/mobile_app.jpeg"}, 
		{id:15,title:'Cloud',desc:"Application backend design and development.",img:"/cloud_pipeline.png"},
		{id:16,title:'Industry: Healthcare',desc:"Pharma, drug design, device design, platform development",img:"/drugAI.jpeg"},
		{id:17,title:'Industry: Electronics',desc:"Process line automation, hardware development.",img:"/electronics.jpeg"},
		{id:18,title:'Industry: Automotive',desc:"Automotive hardware, software, materials.",img:"/adas.jpeg"},
		{id:19,title:'Industry: Real-estate',desc:"Augmented Listing, Sell/Buy side optimization, recruitment and attrition models.",img:"/ar_real_estate.jpeg"},
			];

export default function Home() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon className={classes.icon} />
          <Typography variant="h6" color="inherit" noWrap>
            Semion
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Semion AI Consultancy
            </Typography>
            <Typography display="block" component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>
              Your one stop shop for end to end AI solutions.
            </Typography>
            <Typography display="block" component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>
              Simply submit the form with a short description of your requirements and we will take it from there. 
            </Typography>
            <Typography display="block" component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>
              Wheather you want to explore an idea or looking for an enterprise grade solution, we got you.
            </Typography>
          </Container>
          <Container maxWidth="md">
      <MaterialUIFormSubmit
        formName="Tell us about your project:"
        formDescription="Lets get started."
      />
          </Container>
        </div>
        <div className={classes.heroContent}>
            <Typography display="block" component="h3" variant="h4" align="center" color="textPrimary" gutterBottom>
              Browse some of our service categories for inspiration
            </Typography>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.img}
                    title={card.title}
                  />
	{/*"https://source.unsplash.com/random"*/}
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
			{card.title}
                    </Typography>
                    <Typography>
			{card.desc}
                    </Typography>
                  </CardContent>
		{/*
                  <CardActions>
                    <Link to="/album/id">
                      <Button size="small" color="primary">
                        View
                      </Button>
                    </Link>
                  </CardActions>*/}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
	</div>
      </main>
    </React.Fragment>
  );
}
