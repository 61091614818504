import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { setInStorage } from '../../utils/storage';
import apiURL from '../../config/api.json'
import errorConfig from '../../config/errorConfig.json'
import key from '../../config/key.json'
import { withRouter } from 'react-router-dom';
import { convertToMD5 } from './convertToMd5';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login(props) {
  const classes = useStyles();
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [isLogging, setIsLogging] = useState(false)

  //Patient login status
  const patientLoginStatus = (patient_id) => {
    const body = { task: "setPatientLoggedIn", patient_id: patient_id.toString(), logged_in_status: 1 }
    const headers = { headers: { "Content-Type" : "application/json" } }
    axios.post(apiURL.PatientApi, body, headers)
    .then(res => {
      if(!res.data.errorMessage) {
        console.log(res.data)
      } else {
        setIsLogging(false)
        alert("Something went wrong")
      }
    })
    .catch(err => {
      setIsLogging(false)
      alert(err.toString())
    })
  }

  const authenticatePatient = () => {
    const body = { task: "authenticatePatient", phone, password: convertToMD5(password) }
    const headers = { headers: { "Content-Type" : "application/json" } }
    axios.post(apiURL.PatientApi, body, headers)
    .then(res => {
      if (res.data.length !== 0) {
        setInStorage("data", res.data[0])
        setInStorage("patient", res.data[0].di_id.toString())
        patientLoginStatus(res.data[0].di_id.toString())
        setInStorage(key.loginInfoKey, {
          name: res.data[0].name.toString(),
          phone,
          task: "patient"
        });
        setPhone("")
        setPassword("")
        setIsLogging(false)
        
        props.history.push("/home");
     
      } else {
          setIsLogging(false)
          alert(errorConfig.authenticationError);
      }
    })
    .catch(err => {
        setIsLogging(false)
        alert(err.toString());
    })
  }

  //Login Handler
  const loginHandler = e => {
    e.preventDefault();
    if (
      phone.trim() !== "" &&
      password.trim() !== ""
    ) {
        setIsLogging(true) 
        authenticatePatient()
    } else {
        setIsLogging(false)
        alert(errorConfig.requireError);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={loginHandler} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              id="phone"
              label="Phone"
              name="phone"
              autoComplete="phone"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLogging}
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default withRouter(Login)