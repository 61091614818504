import React, { useReducer } from "react";
import { Button, Icon, TextField, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios'

export function MaterialUIFormSubmit(props) {
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1)
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    iconSmall: {
      fontSize: 20
    },
    root: {
      padding: theme.spacing(3, 2)
    },
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400
    }
  }));

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      desc: "",
      email: "",
      submitStatus: "Please submit the form below:",
    }
  );

  const handleSubmit = evt => {
    evt.preventDefault();

    let data = formInput;
    const api_url='https://ndsqskdiff.execute-api.us-east-1.amazonaws.com/prod' //api_url for lambda submitMypMsg
    const body = { task: "submitConsultancyEmail", name: data.name, email: data.email, msg:data.desc }
    const headers = { headers: { "Content-Type" : "application/json" } }
    axios.post(api_url, body, headers)
    .then(response => {
                setFormInput({submitStatus: "Form submitted successfully." });
		//console.log("Success:", JSON.stringify(response))
		})
    .catch(error => {
                setFormInput({ submitStatus: "Form didn't submit. Please check internet connection." });
		console.error("Error:", error);
		//console.error(formInput.submitStatus)
		});
  };

  const handleInput = evt => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  const classes = useStyles();

  console.log(props);

  return (
    <div>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          {props.formName}
        </Typography>
        <Typography component="p">{props.formDescription} {formInput.submitStatus} </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            id="margin-normal"
            name="name"
            defaultValue={formInput.name}
            className={classes.textField}
            helperText="Enter your full name"
            onChange={handleInput}
          />
          <TextField
            label="Email"
            id="margin-normal"
            name="email"
            defaultValue={formInput.email}
            className={classes.textField}
            helperText="e.g. name@gmail.com"
            onChange={handleInput}
          />
          <TextField
            label="Project Description"
            id="margin-normal"
            name="desc"
            defaultValue={formInput.desc}
            className={classes.textField}
            helperText="I want to launch an AI powered marketing app"
            onChange={handleInput}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Submit {/*<Icon className={classes.rightIcon}>send</Icon>*/}
          </Button>
        </form>
      </Paper>
    </div>
  );
}
